body {
  margin: 0;
  padding: 0;
}

.navbar {
  position: fixed; /* 메뉴바를 고정시킵니다. */
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #000000;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.App {
  position: relative;
}

.content {
  padding-top: 70px; /* Navbar 높이만큼 패딩을 추가하여 컨텐츠가 Navbar 아래에 표시되도록 합니다. */
}

.page {
  height: 1000px; /* 임의의 내용을 추가하여 스크롤이 발생하도록 합니다. */
  padding: 1rem;
}

.navbar h1 {
  margin: 0;
  margin-right: auto;
}

nav ul {
  right: 0;
  list-style: none;
  display: flex;
  margin-left: auto;
}

nav li {
  margin-left: 1rem;
}

nav a {
  color: #000000;
  text-decoration: none;
  font-size: 18px;
  padding: 8px;
}

nav a:hover {
  background-color: #555;
}