@media screen and (min-width: 100px) {
  .email-success-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s;
    z-index: 999;
  }

  .email-success-modal {
      position: relative;
      padding: 20px;
      border-radius: 10px;
      width: 80%;
      max-width: 500px;
      animation: fadeIn 0.5s;
      display: flex;             /* 추가 */
      justify-content: center;   /* 추가 */
      align-items: center;       /* 추가 */
      flex-direction: column;    /* 추가 - 자식 요소들을 세로 방향으로 배열 */ 
  }

  .EmailSentSuccessfully {
    width: 260px;
    object-fit: cover;
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    z-index: 1000;
}

  .gray-Text{
    font-size: 10px;
    color: grey;
    font-family: 'Eina01-Regular'; /* 원하는 글꼴 이름을 지정합니다. */
   }

  .centerd-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* 컨테이너를 화면 높이로 채움 */
  }
  .booking-container {
    justify-content: space-around;   /* This will add some space around the image and the form */
    align-items: flex-start;   /* This will align the image and the form at the top */
  }

  .booking-image {
    display: block; /* 이미지가 가운데 정렬되도록 block 요소로 설정합니다. */
    margin: 0 auto; /* 왼쪽과 오른쪽 여백을 자동으로 조정하여 가운데로 정렬합니다. */
    width: 100%;
    height: 100%;
    object-fit: cover; /* 이미지 비율 유지하면서 컨테이너에 맞춤 */ 
  }

  .booking-form-container {
    flex-grow: 1;   /* This will make the form take the remaining space */
    justify-content: center;
    align-items: center;
  }


  .booking-item {
    display: flex;
    flex-direction: column; /* Arrange text and input in a column */
    margin-bottom: 10px;
    width: 325px;
    margin-top: 10px;
    
  }

  .booking-item label {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    margin-bottom: 5px;
  }
  
  .booking-item input,
  .booking-item textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .booking-item textarea {
    resize: vertical;
  }
  
  .booking-item-date {
    display: inline-block;
  }

  .booking-input {
    width: 100%;  /* Adjust this value as needed */
  }

  .booking-submit-button {
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 120px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #8dcdf8;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .booking-submit-button:hover {
    background-color: #7FBFE4;
  }
}

@media screen and (min-width: 768px) {
  .email-success-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s;
    z-index: 999;
  }

  .email-success-modal {
      position: relative;
      padding: 20px;
      border-radius: 10px;
      width: 80%;
      max-width: 500px;
      animation: fadeIn 0.5s;
      display: flex;             /* 추가 */
      justify-content: center;   /* 추가 */
      align-items: center;       /* 추가 */
      flex-direction: column;    /* 추가 - 자식 요소들을 세로 방향으로 배열 */ 
  }

  .EmailSentSuccessfully {
    width: 500px;
    object-fit: cover;
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    z-index: 1000;
}

  .gray-Text{
   font-size: 10px;
   color: grey;
   font-family: 'Eina01-Regular'; /* 원하는 글꼴 이름을 지정합니다. */
  }

  .centerd-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* 컨테이너를 화면 높이로 채움 */
  }

  .booking-container {
    display: flex;   /* Add this line to make the image and the form be side by side */
    justify-content: space-around;   /* This will add some space around the image and the form */
    align-items: flex-start;   /* This will align the image and the form at the top */
  }

  .booking-image {
    width: auto;
    height: 720px;
    object-fit: cover;
  }

  .booking-form-container {
    flex-grow: 1;   /* This will make the form take the remaining space */
  }


  .booking-item {
    display: flex;
    flex-direction: column; /* Arrange text and input in a column */
    margin-bottom: 10px;
    margin-left: 50px;
    margin-top: 10px;
  }

  .booking-item label {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    margin-bottom: 5px;
  }
  
  .booking-item input,
  .booking-item textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .booking-item textarea {
    resize: vertical;
  }
  
  .booking-item-date {
    display: inline-block;
  }

  .booking-input {
    width: 100%;  /* Adjust this value as needed */
  }

  .booking-submit-button {
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 139px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #8dcdf8;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .booking-submit-button:hover {
    background-color: #7FBFE4;
  }

}

@media screen and (min-width: 1300px) {
  .email-success-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s;
    z-index: 999;
  }

  .email-success-modal {
      position: relative;
      padding: 20px;
      border-radius: 10px;
      width: 80%;
      max-width: 500px;
      animation: fadeIn 0.5s;
      display: flex;             /* 추가 */
      justify-content: center;   /* 추가 */
      align-items: center;       /* 추가 */
      flex-direction: column;    /* 추가 - 자식 요소들을 세로 방향으로 배열 */ 
  }
  
  .EmailSentSuccessfully {
    width: 500px;
    object-fit: cover;
  }

  @keyframes fadeIn {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
  }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    z-index: 1000;
}

  .gray-Text{
    font-size: 10px;
    color: grey;
    font-family: 'Eina01-Regular'; /* 원하는 글꼴 이름을 지정합니다. */
   }

  .centerd-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* 컨테이너를 화면 높이로 채움 */
  }

  .booking-container {
    display: flex;   /* Add this line to make the image and the form be side by side */
    justify-content: space-around;   /* This will add some space around the image and the form */
    align-items: flex-start;   /* This will align the image and the form at the top */
  }

  .booking-image {
    width: auto;
    height: 720px;
    object-fit: cover;
  }

  .booking-form-container {
    flex-grow: 1;   /* This will make the form take the remaining space */
  }


  .booking-item {
    display: flex;
    flex-direction: column; /* Arrange text and input in a column */
    margin-bottom: 10px;
    margin-left: 50px;
    width: 300px;
    margin-top: 10px;
  }

  .booking-item label {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    margin-bottom: 5px;
  }
  
  .booking-item input,
  .booking-item textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .booking-item textarea {
    resize: vertical;
  }
  
  .booking-item-date {
    display: inline-block;
  }

  .booking-input {
    width: 100%;  /* Adjust this value as needed */
  }

  .booking-submit-button {
    margin-top: 20px;
    margin-bottom: 50px;
    margin-left: 139px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #8dcdf8;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .booking-submit-button:hover {
    background-color: #7FBFE4;
  }
}
