@media screen and (min-width: 0px) {
  /* 화면이 768px 이상일 때, 더 큰 폰트 사이즈를 설정합니다. */
  .tab-bar {
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10; 
    z-index: 999;
    background-color: #ffffff;
  }

  .tab-bar {
    flex-wrap: wrap;
  }

  .tab-buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .tab-bar button {
    margin: 10px;
    color: black;
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
  } 

  .loesuyMainLogo-image {
    height: 30px;
    margin-left: 6px;
  }
  
  .loesuyMainLogo-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer; 
  }
  
  .tab-buttons {
    display: flex;
    align-items: center;
    margin-left: auto; /* Design, Works, Booking, Exc 버튼들을 오른쪽으로 이동 */
  }
  
  .tab-bar button {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: normal;
  }
  
  .tab-bar button.active {
    color: #7FBFE4;
    font-weight: bold;
  }
  
  .tab-button {
    margin-right: 10px;
  }
  
  .instagram-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
  }
  
  .instagram-button img {
    width: 18px;
    height: 18px;
    margin-top: 7px;
    margin-right: 15px;
  }
}

@media screen and (min-width: 768px) {
  /* 화면이 1024px 이상일 때, 더 큰 폰트 사이즈를 설정합니다. */
  .tab-bar {
    position: fixed;
    top: 0;
    width: auto;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    background-color: #ffffff;
  }
  
  .tab-buttons {
    justify-content: center;
  }

  .tab-bar button {
    margin: 5px;
    color: black;
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
   }

  .loesuyMainLogo-image {
    height: 40px;
    margin-right: 10px;
  }
  
  .loesuyMainLogo-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .tab-buttons {
    display: flex;
    align-items: center;
    margin-left: auto; /* Design, Works, Booking, Exc 버튼들을 오른쪽으로 이동 */
  }
  
  .tab-bar button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-weight: normal;
  }
  
  .tab-bar button.active {
    color: #7FBFE4;
    font-weight: bold;
  }
  
  .tab-button {
    margin-right: 10px; /* 버튼들 간의 간격을 10px로 유지 */
  }
  
  .instagram-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
  }
  
  .instagram-button img {
    width: 22px;
    height: 22px;
  }
}

@media screen and (min-width: 1300px) {
  /* 화면이 1024px 이상일 때, 더 큰 폰트 사이즈를 설정합니다. */
  .tab-bar {
    position: fixed;
    top: 0;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px; /* 굵기를 10px로 조정 */
    z-index: 999;
    background-color: #ffffff;
  }

  .tab-bar {
    flex-wrap: wrap;
  }

  .tab-buttons {
    justify-content: center;
  }

  .tab-bar button {
    margin: 5px;
    color: black;
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
  }

  .loesuyMainLogo-image {
    height: 40px;
    margin-right: 10px;
  }
  
  .loesuyMainLogo-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .tab-buttons {
    display: flex;
    align-items: center;
    margin-left: auto; /* Design, Works, Booking, Exc 버튼들을 오른쪽으로 이동 */
  }
  
  .tab-bar button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-weight: normal;
  }
  
  .tab-bar button.active {
    color: #7FBFE4;
    font-weight: bold;
  }
  
  .tab-button {
    margin-right: 10px; /* 버튼들 간의 간격을 10px로 유지 */
  }
  
  .instagram-button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
  }
  
  .instagram-button img {
    width: 22px;
    height: 22px;
  }
}

/* 화면 너비가 768px 이하일 때 */
@media screen and (max-width: 768px) {
  .tab-bar {
    flex-wrap: wrap;
  }

  .tab-buttons {
    justify-content: center;
  }

  .tab-bar button {
    margin: 5px;
  }
}


