.snowflake {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 9999;
  animation-name: fall;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes fall {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
}
