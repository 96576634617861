.page1, .page2, .page3, .page4 {
  position: fixed;
  top: 10%; 
  left: -14px;
  width: 100%;
  height: 100vh;
  transform: translateY(100vh);
  transition: transform 0.5s ease-in-out;
}

.page1.active, .page2.active, .page3.active, .page4.active {
  transform: translateY(0); /* 중앙에 위치하도록 조절 */
}
.page1.fade-out, .page2.fade-out, .page3.fade-out, .page4.fade-out {
  transform: translateY(-100%);
}


@media screen and (min-width: 0px) {
  .scrollDownHand-image {
    display: block;
    margin: 0 auto;
    margin-top: 170px;
    width: auto;
    height: 80px;
    object-fit: cover;
  }

  .RealTattoo-image {
    display: block;
    margin: 0 auto;
    width: 350px;
    height: 150px;
    object-fit: cover;
  }
  .rounded-button {
    font-size: 16px;
    font-weight: bold; /* 글자를 굵게 설정합니다. */
    padding: 10px 20px;
    border-radius: 20px; /* 버튼의 모서리를 20px로 둥글게 조정합니다. */
    background-color: #8dcdf8;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .spacer {
    height: 80px; /* 원하는 간격 높이를 설정합니다. */
  }

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  .blinking-text {
    animation: blink 1s infinite;
  }

  .tattooDesign-image-Home {
    display: block; /* 이미지가 가운데 정렬되도록 block 요소로 설정합니다. */
    width: 100px;
    height: 150px;  
    margin-left: 10px;
    z-index: 1;
    position: relative;
    object-fit: cover; /* 이미지 비율 유지하면서 컨테이너에 맞춤 */ 
  }

  .container {
    overflow: hidden;
    position: relative;
    height: 100vh;
  }

  .page1, .page2, .page3, .page4 {
    position: fixed;
    top: 35%; 
}

.page1.active, .page2.active, .page3.active, .page4.active {
    transform: translateY(0); /* 중앙에 위치하도록 조절 */
}


  /* 화면이 768px 이상일 때, 더 큰 폰트 사이즈를 설정합니다. */
  .big-text1 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    font-size: 28px;
    text-align: center;
    margin-bottom:-23px; /* 원하는 여백 값으로 조정합니다. */
    z-index: 1;
    position: relative;  
  }
  .big-text2 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    font-size: 25px;
    text-align: center;
    margin-bottom:-23px; /* 원하는 여백 값으로 조정합니다. */
    z-index: 1;
    position: relative;  
  }

  .small-text {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    font-size: 18px;
    text-align: center;
    margin-bottom:10px; /* 원하는 여백 값으로 조정합니다. */
  }

  .underline-text1 {
    position: relative;
    font-weight: bold; /* 굵기 설정 */
  }
  
  .underline-text1::after {
    content: "";
    position: absolute;
    left: 0;
    height: 2px;
    bottom: 0%;
    background-color: #8dcdf8;
    animation: underline-anim 1.5s forwards;
    transform-origin: left;
  }
  
  .underline-text2 {
    position: relative;
    font-weight: bold; /* 굵기 설정 */
  }
  
  .underline-text2::after {
    content: "";
    position: absolute;
    left: 0;
    height: 2px;
    bottom: -10%;
    background-color: #8dcdf8;
    animation: underline-anim 1.5s forwards;
    transform-origin: left;
    animation-delay: 1s; /* 이 속성이 애니메이션 시작을 2초 지연시킵니다. */
  }
  
  .highlight {
    color: #7FBFE4; /* 원하는 색상으로 변경합니다. */
  }

  .booking-submit-button:hover {
    background-color: #7FBFE4;
  }
  
  .tattooDesign-container-Home {
    display: flex; /* Flexbox 컨테이너로 설정합니다. */
    justify-content: center; /* 이미지를 가운데로 정렬합니다. */
    margin-left: -12px;
  }

  .HomeDesignMovebitton-image-Home {
    width: 175px;
    height: auto; 
    margin-top: -20%; 
  }
}

@media screen and (min-width: 768px) {
  .scrollDownHand-image {
    display: block;
    margin: 0 auto;
    margin-top: 100px;
    width: auto;
    height: 150px;
    object-fit: cover;
  }

  .RealTattoo-image {
    margin: 0 auto;
    height: 400px;
    width: 950px;
    object-fit: cover; 
  }
  
  .rounded-button {
    font-size: 16px;
    font-weight: bold; /* 글자를 굵게 설정합니다. */
    padding: 10px 20px;
    border-radius: 20px; /* 버튼의 모서리를 20px로 둥글게 조정합니다. */
    background-color: #8dcdf8;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .spacer {
    height: 80px; /* 원하는 간격 높이를 설정합니다. */
  }
  /* In your App.css or any other CSS file */
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  .blinking-text {
    animation: blink 1s infinite;
  }
  .tattooDesign-image-Home {
    display: block; /* 이미지가 가운데 정렬되도록 block 요소로 설정합니다. */
    width: 250px;
    height: 290px;  
    margin-right: 50px;
    z-index: 1;
    position: relative;  
  }

  .HomeDesignMovebitton-image-Home {
    width: 300px;
    height: auto; 
    margin-top: -10%; 
  }

  .container {
    overflow: hidden;
    position: relative;
    height: 100vh;
    overflow-y: hidden;
  }

  .page1, .page2, .page3, .page4 {
    position: fixed;
    top: 20%; 
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateY(100vh);
    transition: transform 0.5s ease-in-out;
}

.page1.active, .page2.active, .page3.active, .page4.active {
    transform: translateY(0); /* 중앙에 위치하도록 조절 */
}

.big-text1 {
  font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
  font-size: 85px;
  text-align: center;
  margin:0px 0; /* 원하는 여백 값으로 조정합니다. */
  z-index: 1;
  position: relative;  
  letter-spacing: 0.5px;  /* 글자 간격을 0.5픽셀 늘림 */
}

.big-text2 {
  font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
  font-size: 70px;
  text-align: center;
  margin:0px 0; /* 원하는 여백 값으로 조정합니다. */
  z-index: 1;
  position: relative;  
  letter-spacing: 0.5px;  /* 글자 간격을 0.5픽셀 늘림 */
}

.small-text {
  font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
  font-size: 43px;
  text-align: center;
  margin-bottom:20px; /* 원하는 여백 값으로 조정합니다. */
  letter-spacing: 0.5px;  /* 글자 간격을 0.5픽셀 늘림 */
}

.underline-text1 {
  position: relative;
  font-weight: bold; /* 굵기 설정 */
}

.underline-text1::after {
  content: "";
  position: absolute;
  left: 0;
  height: 5px;
  bottom: 0%;
  background-color: #8dcdf8;
  animation: underline-anim 1.5s forwards;
  transform-origin: left;
}

.underline-text2 {
  position: relative;
  font-weight: bold; /* 굵기 설정 */
}

.underline-text2::after {
  content: "";
  position: absolute;
  left: 0;
  height: 5px;
  bottom: -10%;
  background-color: #8dcdf8;
  animation: underline-anim 1.5s forwards;
  transform-origin: left;
  animation-delay: 1s; /* 이 속성이 애니메이션 시작을 2초 지연시킵니다. */
}

.highlight {
  color: #7FBFE4; /* 원하는 색상으로 변경합니다. */
}

.booking-submit-button:hover {
  background-color: #7FBFE4;
}

.tattooDesign-container-Home {
  display: flex; /* Flexbox 컨테이너로 설정합니다. */
  justify-content: center; /* 이미지를 가운데로 정렬합니다. */
  margin-left: 6%;
}
}
