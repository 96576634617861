@media screen and (min-width: 100px) {
  .tattooDesign-Main-Design {
    margin-top: 10px;
  }

  .tattooDesign-container-Design {
    display: grid; /* Flexbox 대신 CSS Grid를 사용하여 5열로 이미지들을 배치합니다. */
    grid-template-columns: repeat(2, 1fr); /* 5개의 동일한 크기의 컬럼으로 구성합니다. */
    gap: 0px; /* 이미지들 사이의 간격을 조정합니다. */
    justify-items: center; /* 이미지들을 수평으로 가운데 정렬합니다. */
  }

  /* 이미지 갤러리의 각 이미지들을 감싸는 div의 클래스 */
  .image-item-Design {
    margin: 10px; /* 이미지들의 외부 간격을 조정합니다. */
    position: relative;
    overflow: hidden; /* 이미지가 컨테이너를 벗어나지 않게 함 */
  }

  /* 이미지 갤러리의 이미지 스타일 */
  .image-item-Design img {
    display: block;
    max-width: 100%; /* 이미지의 최대 너비를 조정합니다. */
    height: auto;
    cursor: pointer;
  }

  /* 모달 스타일 */
  .modal-Design {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: #fff; /* Black with opacity */
    /*background-color: rgba(0, 0, 0, 0.7); /* Black with opacity */
  }

  .modal-content-Design {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 20px;
    width: 80%;
    max-width: 80vw;
    width: 100%;  /* 가로 길이를 최대로 설정 */
    background: transparent;  /* 배경색을 투명하게 설정 */
    animation-name: animatetop;
    animation-duration: 0.4s;
  }

  /* 모달의 이미지 스타일 */
  .modal-image-Design {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;  
  }

  .close-Design {
    color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50px;
    right: 20px;
    font-size: 40px;
    z-index: 1;
    margin-top: 20px;
  }

  .close-Design:hover,
  .close-Design:focus {
    color: rgba(0, 0, 0, 0.1);
    text-decoration: none;
    cursor: pointer;
  }

  /* 이전, 다음 버튼 스타일 */
  .arrow-button-Design {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: rgba(0, 0, 0, 0.3);
    font-size: 2rem;
    cursor: pointer;
  }

  .prev-button-Design {
    left: 2px;
  }

  .next-button-Design {
    right: 2px;
  }

  .centered-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* 컨테이너를 화면 높이로 채움 */
  }
  
  /* 예약 버튼 스타일 */
  .booking-button-Design {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #8dcdf8;
    color: #fff;
    transition: background-color 0.3s ease;
    font-size: 16px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }
/* 애니메이션 설정 */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
}

@media screen and (min-width: 768px) {
  .tattooDesign-Main-Design {
    margin-top: 10px;
  }

  .tattooDesign-container-Design {
    display: grid; /* Flexbox 대신 CSS Grid를 사용하여 5열로 이미지들을 배치합니다. */
    grid-template-columns: repeat(5, 1fr); /* 5개의 동일한 크기의 컬럼으로 구성합니다. */
    gap: 20px; /* 이미지들 사이의 간격을 조정합니다. */
    justify-items: center; /* 이미지들을 수평으로 가운데 정렬합니다. */
  }

  /* 이미지 갤러리의 각 이미지들을 감싸는 div의 클래스 */
  .image-item-Design {
    margin: 10px; /* 이미지들의 외부 간격을 조정합니다. */
  }

  /* 이미지 갤러리의 이미지 스타일 */
  .image-item-Design img {
    display: block;
    max-width: 100%; /* 이미지의 최대 너비를 조정합니다. */
    height: auto;
    cursor: pointer;
  }

  /* 모달 스타일 */
  .modal-Design {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: #fff; /* Black with opacity */
    /*background-color: rgba(0, 0, 0, 0.7); /* Black with opacity */
  }

  .modal-content-Design {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 20px;
    width: 80%;
    max-width: 80vw;
    width: 100%;  /* 가로 길이를 최대로 설정 */
    background: transparent;  /* 배경색을 투명하게 설정 */
    animation-name: animatetop;
    animation-duration: 0.4s;
  }

  /* 모달의 이미지 스타일 */
  .modal-image-Design {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;  
  }

  .close-Design {
    color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 50px;
    right: 110px;
    font-size: 50px;
    font-weight: bold;
    z-index: 1;
  }

  .close-Design:hover,
  .close-Design:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  /* 이전, 다음 버튼 스타일 */
  .arrow-button-Design {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: rgba(0, 0, 0, 0.3);
    font-size: 2rem;
    cursor: pointer;
  }

  .prev-button-Design {
    left: 10px;
  }

  .next-button-Design {
    right: 10px;
  }

  .centered-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* 컨테이너를 화면 높이로 채움 */
  }

  /* 예약 버튼 스타일 */
  .booking-button-Design {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #8dcdf8;
    color: #fff;
    transition: background-color 0.3s ease;
    font-size: 16px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }
/* 애니메이션 설정 */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
}

@media screen and (min-width: 1300px) {
  .tattooDesign-Main-Design {
    margin-top: 10px;
  }

  .tattooDesign-container-Design {
    display: grid; /* Flexbox 대신 CSS Grid를 사용하여 5열로 이미지들을 배치합니다. */
    grid-template-columns: repeat(5, 1fr); /* 5개의 동일한 크기의 컬럼으로 구성합니다. */
    gap: 20px; /* 이미지들 사이의 간격을 조정합니다. */
    justify-items: center; /* 이미지들을 수평으로 가운데 정렬합니다. */
  }

  /* 이미지 갤러리의 각 이미지들을 감싸는 div의 클래스 */
  .image-item-Design {
    margin: 10px; /* 이미지들의 외부 간격을 조정합니다. */
  }

  /* 이미지 갤러리의 이미지 스타일 */
  .image-item-Design img {
    display: block;
    max-width: 100%; /* 이미지의 최대 너비를 조정합니다. */
    height: auto;
    cursor: pointer;
  }

  /* 모달 스타일 */
  .modal-Design {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: #fff; /* Black with opacity */
    /*background-color: rgba(0, 0, 0, 0.7); /* Black with opacity */
  }

  .modal-content-Design {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 20px;
    width: 80%;
    max-width: 80vw;
    width: 100%;  /* 가로 길이를 최대로 설정 */
    background: transparent;  /* 배경색을 투명하게 설정 */
    animation-name: animatetop;
    animation-duration: 0.4s;
  }

  /* 모달의 이미지 스타일 */
  .modal-image-Design {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;  
  }

  .close-Design {
    color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 50px;
    right: 110px;
    font-size: 50px;
    font-weight: bold;
    z-index: 1;
  }

  .close-Design:hover,
  .close-Design:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  /* 이전, 다음 버튼 스타일 */
  .arrow-button-Design {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: rgba(0, 0, 0, 0.3);
    font-size: 2rem;
    cursor: pointer;
  }

  .prev-button-Design {
    left: 10px;
  }

  .next-button-Design {
    right: 10px;
  }

  .centered-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* 컨테이너를 화면 높이로 채움 */
  }
  
  /* 예약 버튼 스타일 */
  .booking-button-Design {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #8dcdf8;
    color: #fff;
    transition: background-color 0.3s ease;
    font-size: 16px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }
/* 애니메이션 설정 */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
}
