@font-face {
  font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
  src: url('./032d6b2c34344e22d2cbca6b7050d642.woff') format('woff'), /* 글꼴 파일 경로를 지정합니다. */
       url('./032d6b2c34344e22d2cbca6b7050d642.woff2') format('woff2'),
       url('./032d6b2c34344e22d2cbca6b7050d642.ttf') format('ttf');/* 다른 포맷의 글꼴 파일이 있다면, 각각 포맷에 맞게 추가해주어야 합니다. */
  font-weight: normal;
  font-style: normal;
  }

@font-face {
  font-family: 'Eina01-Regular'; /* 원하는 글꼴 이름을 지정합니다. */
  src: url('./1868e326d3ee28e5395f6efa2bc037bf.woff') format('woff'); /* 글꼴 파일 경로를 지정합니다. */
  font-weight: normal;
  font-style: normal;
  /* 다른 포맷의 글꼴 파일이 있다면, 각각 포맷에 맞게 추가해주어야 합니다. */
}

/* 글꼴을 적용할 요소를 선택합니다. */
body {
  font-family: 'Eina01-SemiBold', sans-serif; /* MyCustomFont가 없을 경우 대체할 기본 글꼴을 지정합니다. */
}
