.new-Noline::after {
  white-space: nowrap;
}
.new-line2::after {
  content: "\A";
  white-space: pre;
}
.new-line::after {
  content: "\A";
  white-space: pre;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
.blinking-text-Info {
  animation: blink 3.5s infinite;
}

@media screen and (min-width: 0px) {
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    width: 90%;
  }
  
  .row.reverse {
    flex-direction: row-reverse;
  }

  .image1 {
    height: 200px; /* 원하는 높이로 설정 */
  }

  .image2 {
    height: 220px; /* 원하는 높이로 설정 */
    margin-left: 70px;
  }

  .image3 {
    height: 220px; /* 원하는 높이로 설정 */
    margin-top: -30px;
  }

  .image4 {
    width: 80%;
    margin-top: 15px;
    margin-left: 30px;
  }

  .text1 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 80%;
    font-size: 13px;
    margin-top: 10px;
    margin-left: 0px;
    text-align: left;
    /*letter-spacing: 1px; /* 2px만큼 자간을 늘림 */
  }

  .text2 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 80%;
    font-size: 13px;
    margin-top: -10px;
    margin-left: 0px;
    text-align: left;
  }

  .text3 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 74%;
    font-size: 13px;
    margin-left: 57px;
    margin-top: 80px;
    text-align: left;
  }

  .text4 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 80%;
    font-size: 13px;
    margin-top: 1px;
    margin-left: 55px;
    text-align: left;
  }

  .text5 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 90%;
    font-size: 14px;
    margin-bottom: 100px;
    margin-top: -20px;
    text-align: center;
  }

  .new-line::after {
    content: "\A";
    white-space: pre;
  }
  .new-line::before {
    content: "\00a0"; /* non-breaking space */
  }
  .new-line2::after {
    content: "\A";
    white-space: pre;
  }
  .new-line2::after {
    content: "\A";
    white-space: pre;
  }
  .new-line3::after {
    white-space: nowrap;
  }
  .new-line4::after {
    content: "\A";
    white-space: pre;
  }
  .new-Noline::after {
    white-space: nowrap;
  }
  .new-Noline::before {
    content: "\00a0"; /* non-breaking space */
  }
}

@media screen and (min-width: 768px) {
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    width: 90%;
  }
  
  .row.reverse {
    flex-direction: row-reverse;
  }

  .image1 {
    height: 250px; /* 원하는 높이로 설정 */
  }

  .image2 {
    height: 250px; /* 원하는 높이로 설정 */
  }

  .image3 {
    height: 250px; /* 원하는 높이로 설정 */
    margin-top: -30px;
  }

  .image4 {
    width: 80%;
    margin-top: -0px;
    margin-left: 30px;
  }

  .text1 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 100%;
    font-size: 13px;
    margin-top: 180px;
    margin-left: 10px;
    text-align: left;
    /*letter-spacing: 1px; /* 2px만큼 자간을 늘림 */
  }

  .text2 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 100%;
    font-size: 13px;
    margin-top: 70px;
    margin-left: 0px;
    text-align: left;
  }

  .text3 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 100%;
    font-size: 13px;
    text-align: left;
  }

  .text4 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 100%;
    font-size: 13px;
    margin-top: 150px;
    text-align: left;
  }

  .text5 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 100%;
    font-size: 13px;
    margin-bottom: 100px;
    margin-top: -40px;
    text-align: center;
  }
  .new-line::after {
    content: "\A";
    white-space: pre;
  }
  .new-line2::after {
    all:unset;
  }
  .new-line3::after {
    content: "\A";
    white-space: pre;
  }
  .new-line4::after {
    all:unset;
  }
  .new-line4::before {
    content: "\00a0"; /* non-breaking space */
  }
  .new-Noline::after {
    all: unset;
  }
}

@media screen and (min-width: 1300px) {
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    width: 90%;
  }
  
  .row.reverse {
    flex-direction: row-reverse;
  }

  .image1 {
    height: 400px; /* 원하는 높이로 설정 */
  }

  .image2 {
    height: 400px; /* 원하는 높이로 설정 */
    margin-right: 70px;
  }

  .image3 {
    height: 400px; /* 원하는 높이로 설정 */
    margin-top: -30px;
  }

  .image4 {
    width: 600px;
    margin-top: -30px;
  }

  .text1 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 100%;
    font-size: 18px;
    margin-top: 260px;
    margin-left: 10px;
    text-align: left;
    /*letter-spacing: 1px; /* 2px만큼 자간을 늘림 */
  }

  .text2 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 100%;
    font-size: 18px;
    margin-top: 100px;
    margin-left: 45px;
    text-align: left;
  }

  .text3 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 100%;
    font-size: 18px;
    margin-left: 255px;
    margin-top: -50px;
    text-align: left;
  }

  .text4 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 100%;
    font-size: 18px;
    margin-top: 240px;
    text-align: left;
  }

  .text5 {
    font-family: 'Eina01-SemiBold'; /* 원하는 글꼴 이름을 지정합니다. */
    width: 100%;
    font-size: 18px;
    margin-bottom: 100px;
    margin-top: -40px;
    text-align: center;
  } 
  .new-line::after {
    content: "\A";
    white-space: pre;
  }
  .new-line2::after {
    all:unset;
  }

  .new-line3::after {
    content: "\A";
    white-space: pre;
  }

  .new-line4::after {
    all:unset;
  }

  .new-line4::before {
    content: "\00a0"; /* non-breaking space */
  }

  .new-Noline::after {
    all: unset;
  }
}
