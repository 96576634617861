/* App.css */
@media screen and (min-width: 100px) {
  body {
    margin: 0;
    padding: 0;
  }
  .content {
    padding-top: 60px; /* 탭바의 높이만큼 패딩을 추가하여 컨텐츠가 탭바 아래에 표시되도록 합니다. */
  }
  .App {
    margin-left: 20px; /* 왼쪽 여백 크기로 조정 */
    margin-right: 20px; /* 오른쪽 여백 크기로 조정 */
  }
  body {
    margin: 0;
    padding: 0;
  }
  @keyframes underline-anim {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}

@media screen and (min-width: 768px) {
  body {
    margin: 0;
    padding: 0;
  }
  .content {
    padding-top: 60px; /* 탭바의 높이만큼 패딩을 추가하여 컨텐츠가 탭바 아래에 표시되도록 합니다. */
  }
  .App {
    margin-left: 100px; /* 왼쪽 여백 크기로 조정 */
    margin-right: 100px; /* 오른쪽 여백 크기로 조정 */
  }
  body {
    margin: 0;
    padding: 0;
  }
  @keyframes underline-anim {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}

@media screen and (min-width: 1300px) {
  body {
    margin: 0;
    padding: 0;
  }
  .content {
    padding-top: 60px; /* 탭바의 높이만큼 패딩을 추가하여 컨텐츠가 탭바 아래에 표시되도록 합니다. */
  }
  .App {
    margin-left: 100px; /* 왼쪽 여백 크기로 조정 */
    margin-right: 100px; /* 오른쪽 여백 크기로 조정 */
  }
  body {
    margin: 0;
    padding: 0;
  }
  @keyframes underline-anim {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}





